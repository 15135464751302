import styled from 'styled-components';
import FirstBg from 'common/assets/image/syncStage/pattern-1@2x.png';

const TermsSectionWrapper = styled.section`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    radial-gradient(
      89.85% 105.84% at 44.89% 3.77%,
      #00fff0 0%,
      #00c2ff 17.71%,
      #004cdf 40.63%,
      #321497 58.85%,
      #9800cd 81.25%,
      #cd00a0 100%
    );
  z-index: 0;
  padding: 0 200px;

  .back-link {
    img {
      margin: 60px 0 0 -80px;
    }
  }

  @media (max-width: 990px) {
    padding: 0 150px;
    .back-link {
      img {
        margin: 60px 0 0 -60px;
      }
    }
  }
  @media (max-width: 767px) {
    padding: 0 60px;
    .back-link {
      img {
        margin: 60px 0 0 -40px;
      }
    }
  }
  @media (max-width: 575px) {
    padding: 0 20px;
    .back-link {
      img {
        margin: 60px 0 0 0;
      }
    }
  }

  .first-bg {
    background-image: url(${FirstBg});
    background-position: top right;
    background-size: contain;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 1800px;
  }

  ol {
    margin: 0;
    padding: 0;
  }

  table td,
  table th {
    padding: 0;
  }

  .c11 {
    margin-left: 36pt;
    padding-top: 7pt;
    padding-left: 0pt;
    padding-bottom: 7pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c0 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c14 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: right;
  }

  .c17 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c12 {
    padding-top: 0pt;
    padding-bottom: 17pt;
    line-height: 1.2;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c3 {
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
  }

  .c10 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 23pt;
  }

  .c5 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
  }

  .c15 {
    text-decoration-skip-ink: none;
    text-decoration: underline;
  }

  .c13 {
    max-width: 468pt;
    padding: 72pt 72pt 72pt 72pt;
  }

  .c1 {
    font-weight: 700;
  }

  .c8 {
    font-weight: 400;
  }

  .c6 {
    padding: 0;
    margin: 0;
  }

  .c16 {
    color: #36dbfd;
    text-decoration: inherit;
  }

  .c4 {
    font-style: normal;
  }

  .c2 {
    font-style: italic;
  }

  .c9 {
    height: 11pt;
  }

  .title {
    padding-top: 0pt;

    font-size: 26pt;
    padding-bottom: 3pt;

    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .subtitle {
    padding-top: 0pt;

    font-size: 15pt;
    padding-bottom: 16pt;

    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  li {
    font-size: 11pt;
    list-style-type: circle;
  }

  p {
    margin: 0;
    font-size: 11pt;
  }

  h3 {
    padding-top: 16pt;

    font-size: 14pt;
    padding-bottom: 4pt;

    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h4 {
    padding-top: 14pt;

    font-size: 12pt;
    padding-bottom: 4pt;

    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h5 {
    padding-top: 12pt;

    font-size: 11pt;
    padding-bottom: 4pt;

    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h6 {
    padding-top: 12pt;

    font-size: 11pt;
    padding-bottom: 4pt;

    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .contact {
    color: #36dbfd;
    cursor: pointer;
    font-weight: 600;
  }
`;

export default TermsSectionWrapper;
