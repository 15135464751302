import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import PolicySectionWrapper from './policy.style';
import Text from 'common/components/Text';
import BackIcon from 'common/assets/image/syncStage/back.png';
import { handleContact } from 'common/handlers';
import { Modal } from '@redq/reuse-modal';

// TODO: The HTML content is exported from Google Docs. We need to find a nicer way to export HTML.
const PolicySection = ({ title, content }) => {
  return (
    <PolicySectionWrapper>
      <Modal />
      <Container fullWidth noGutter>
        <div className="first-bg" />
        <a href="/" className="back-link">
          <img src={BackIcon} alt="Back" />
        </a>
        <Box>
          <Heading {...title} content="Privacy Policy" />
        </Box>
        <Box>
          <Text {...content}>
            <React.Fragment>
              <p className="c0">
                <span className="c1">Last Updated:</span>
                <span className="c5 c1 c4">&nbsp;12 December 2021</span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c7">Thank you for choosing to be part of our community at </span>
                <span className="c1">Open Sesame Media, Inc.</span>
                <span className="c7">&nbsp;and </span>
                <span className="c1">SyncStage</span>
                <span className="c7">&nbsp;(also included below as &ldquo;</span>
                <span className="c1">OSM</span>
                <span className="c7">&rdquo;, &quot;</span>
                <span className="c1">Company</span>
                <span className="c7">&quot;, &quot;</span>
                <span className="c1">we</span>
                <span className="c7">&quot;, &quot;</span>
                <span className="c1">us</span>
                <span className="c7">&quot;, &quot;</span>
                <span className="c1">our</span>
                <span className="c3 c4">
                  &quot;). We are committed to protecting your personal information and your right to privacy. If you
                  have any questions or concerns about this privacy notice, or our practices with regards to your
                  personal information, please contact us using our{' '}
                  <span className="contact" onClick={handleContact}>
                    contact form
                  </span>
                  .
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c7">When you use any of our services (the &quot;</span>
                <span className="c1">Services</span>
                <span className="c3 c4">
                  &quot;), we appreciate you trusting us with your personal information. We take privacy very seriously.
                  In this Privacy Policy notice, we seek to explain to you in the clearest way possible what information
                  we collect, how we use it, and what rights you have in relation to it. Please read through this
                  document carefully, as it is important. If there are any terms in this Privacy Policy notice that you
                  do not agree with, please discontinue use of our Services immediately.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  This privacy notice applies to all information collected through our Services (which includes OSM,
                  SyncStage, and our applications), as well as, any related services, sales, marketing or events.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c5 c1 c4">
                  Please read this privacy notice as it will help you understand what we do with the information that we
                  collect.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c5 c1 c4">1. WHAT INFORMATION DO WE COLLECT?</span>
              </p>
              <p className="c0">
                <span className="c1 c2">Briefly:</span>
                <span className="c1">&nbsp;</span>
                <span className="c5 c1 c2">We collect personal information that you provide to us.</span>
              </p>
              <p className="c0">
                <span className="c3 c4">
                  We collect personal information that you voluntarily provide to us when you register on one of our
                  websites, express an interest in obtaining information from us or our products and Services, when you
                  post messages in our online forum(s) or entering competitions, contests or giveaways, or otherwise
                  when you contact us.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  The personal information that we collect depends on the context of your interactions with us and the
                  choices you make and the products and features you use. The personal information we collect may
                  include the following:
                </span>
              </p>
              <p className="c0">
                <span className="c1">Social Media Login Data</span>
                <span className="c7">
                  . We may provide you with the option to register with us using your existing social media account
                  details, like your Facebook, Twitter or other social media account. If you choose to register in this
                  way, we will collect the Information described in the section called &quot;
                </span>
                <span className="c1">HOW DO WE HANDLE YOUR SOCIAL LOGINS</span>
                <span className="c3 c4">&quot; below.</span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  All personal information that you provide to us must be true, complete and accurate, and you must
                  notify us of any changes to such personal information.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c5 c1 c4">Information automatically collected</span>
              </p>
              <p className="c0">
                <span className="c1 c2">Briefly:</span>
                <span className="c3 c2">
                  &nbsp;Some information &mdash; such as your Internet Protocol (IP) address and/or browser and device
                  characteristics &mdash; is collected automatically when you visit our websites, social media, and/or
                  forums.
                </span>
              </p>
              <p className="c0">
                <span className="c3 c4">
                  We automatically collect certain information when you visit, use or navigate our Services. This
                  information does not necessarily reveal your specific identity (like your name or contact information)
                  but may include device and usage information, such as your IP address, browser and device
                  characteristics, operating system, language preferences, referring URLs, device name, country,
                  location, information about how and when you use our and other technical information. This information
                  is primarily needed to maintain the security and operation of our Services and operations, and for our
                  internal analytics and reporting purposes.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  Like many businesses, we also collect information through cookies and similar technologies.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c5 c1 c4">2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span>
              </p>
              <p className="c0">
                <span className="c1 c2">Briefly</span>
                <span className="c3 c2">
                  : We only share information with your consent, to comply with laws, to provide you with services, to
                  protect your rights, or to fulfill business obligations.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  We may process or share your data that we hold based on the following legal basis:
                </span>
              </p>
              <p className="c0">
                <span className="c3 c4">
                  Specifically, we may need to process your data or share your personal information in the following
                  situations:
                </span>
              </p>
              <ul className="c6 lst-kix_g21118a7i852-0 start">
                <li className="c11 li-bullet-0">
                  <span className="c3 c4">
                    Business Transfers. We may share or transfer your information in connection with, or during
                    negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion
                    of our business to another company.
                  </span>
                </li>
              </ul>
              <ul className="c6 lst-kix_mfhedejy3xuq-0 start">
                <li className="c11 li-bullet-0">
                  <span className="c3 c4">
                    Affiliates. We may share your information with our affiliates, in which case we will require those
                    affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries,
                    joint venture partners or other companies that we control or that are under common control with us.
                  </span>
                </li>
              </ul>
              <ul className="c6 lst-kix_2kuqatd2mvt2-0 start">
                <li className="c11 li-bullet-0">
                  <span className="c3 c4">
                    Business Partners. We may share your information with our business partners to offer you certain
                    products, services or promotions.
                  </span>
                </li>
              </ul>
              <ul className="c6 lst-kix_7skxylqsxxb7-0 start">
                <li className="c11 li-bullet-0">
                  <span className="c3 c4">
                    Other Users. When you share personal information or otherwise interact with public areas of
                    website(s), such personal information may be viewed by all users and may be publicly made available
                    outside the Company in perpetuity. If you interact with other users of our ecosystem and register
                    through a social network (such as Facebook), your contacts on the social network will or may see
                    your name, profile photo, and descriptions of your activity. Similarly, other users will or may be
                    able to view descriptions of your activity, communicate with you within our Services, and view your
                    profile.
                  </span>
                </li>
              </ul>
              <p className="c0">
                <span className="c1 c4 c5">3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span>
              </p>
              <p className="c0">
                <span className="c1 c2">Briefly:</span>
                <span className="c3 c2">
                  &nbsp;We may use cookies and other tracking technologies to collect and store your information.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store
                  information. Specific information about how we use such technologies and how you can refuse certain
                  cookies is set out in our Cookie Notice.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c5 c1 c4">4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>
              </p>
              <p className="c0">
                <span className="c1 c2">Briefly:</span>
                <span className="c2 c3">
                  &nbsp;If you choose to register or log in to our services using a social media account, we may have
                  access to certain information about you.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  Our login selections may permit you the ability to register and login using your third-party social
                  media account details (like your Facebook or Twitter logins). Where you choose to do this, we will
                  receive certain profile information about you from your social media provider. The profile Information
                  we receive may vary depending on the social media provider concerned, but will often include your
                  name, email address, friends list, profile picture as well as other information you choose to make
                  public on such social media platform.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  We will use the information we receive only for the purposes that are described in this privacy notice
                  or that are otherwise made clear to you on the relevant third-party social media service you used in
                  registering on one of our Services. Please note that we do not control, and are not responsible for
                  the information included and/or uses of your personal information by your third-party social media
                  provider. We recommend that you review their privacy notice to understand how they collect, use and
                  share your personal information, and how you can set your privacy preferences on their sites and apps.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c5 c1 c4">5. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span>
              </p>
              <p className="c0">
                <span className="c1 c2">Briefly:</span>
                <span className="c3 c2">
                  &nbsp;We may transfer, store, and process your information in countries other than your own.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c7">We utilize in-house systems and third-party cloud based servers</span>
                <span className="c3 c4">
                  , please be aware that your information may be transferred to, stored, and processed by us in our
                  facilities and by those third parties with whom we may share your personal information (see &quot;WILL
                  YOUR INFORMATION BE SHARED WITH ANYONE?&quot; above), in and other countries.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  If you are a resident in the European Economic Area, then these countries may not necessarily have
                  data protection laws or other similar laws as comprehensive as those in your country. We will however
                  take all necessary measures to protect your personal information in accordance with this privacy
                  notice and applicable law.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c5 c1 c4">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span>
              </p>
              <p className="c0">
                <span className="c1 c2">Briefly:</span>
                <span className="c3 c2">
                  &nbsp;We keep your information for as long as necessary to fulfill the purposes outlined in this
                  privacy notice unless otherwise required by law.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  We will only keep your personal information for as long as it is necessary for the purposes
                  established in this Public Privacy notice, unless a longer retention period is required or permitted
                  by law (such as tax, accounting or other legal requirements).{' '}
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  When we have no ongoing legitimate business need to process your personal information, we will either
                  delete or anonymize such information, or, if this is not possible (for example, because your personal
                  information has been stored in backup archives), then we will securely store your personal information
                  and isolate it from any further processing until deletion is possible.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c5 c1 c4">7. DO WE COLLECT INFORMATION FROM MINORS?</span>
              </p>
              <p className="c0">
                <span className="c1 c2">Briefly:</span>
                <span className="c3 c2">
                  &nbsp;We do not knowingly collect data from or market to children under 18 years of age.
                </span>
              </p>
              <p className="c0">
                <span className="c3 c4">
                  We do not knowingly solicit data from or market to children under 18 years of age. By using the , you
                  represent that you are at least 18 or that you are the parent or guardian of such a minor and consent
                  to such minor dependent&rsquo;s use of the . If we learn that personal information from users less
                  than 18 years of age has been collected, we will deactivate the account and take reasonable measures
                  to promptly delete such data from our records. If you become aware of any data we may have collected
                  from children under age 18, please contact us using our{' '}
                  <span className="contact" onClick={handleContact}>
                    contact form
                  </span>
                  .
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c5 c1 c4">8. WHAT ARE YOUR PRIVACY RIGHTS?</span>
              </p>
              <p className="c0">
                <span className="c1 c2">Briefly:</span>
                <span className="c3 c2">&nbsp;You may review, change, or terminate your account at any time.</span>
              </p>
              <p className="c0">
                <span className="c7">
                  If you are a resident in the European Economic Area and you believe we are unlawfully processing your
                  personal information, you also have the right to complain to your local data protection supervisory
                  authority. You can find their contact details here:{' '}
                </span>
                <span className="c15">
                  <a className="c16" href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
                    http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                  </a>
                </span>
                <span className="c3 c4">.</span>
              </p>
              <p className="c0">
                <span className="c7">
                  If you are a resident in Switzerland, the contact details for the data protection authorities are
                  available here:{' '}
                </span>
                <span className="c15">
                  <a className="c16" href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                    https://www.edoeb.admin.ch/edoeb/en/home.html
                  </a>
                </span>
                <span className="c3 c4">.</span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c5 c1 c4">Account Information</span>
              </p>
              <p className="c0">
                <span className="c3 c4">
                  If you would at any time like to review or change the information in your account or terminate your
                  account, you can:
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  Upon your request to terminate your account, we will deactivate or delete your account and information
                  from our active databases. However, we may retain some information in our files to prevent fraud,
                  troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with
                  applicable legal requirements.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  Opting out of email marketing: You can unsubscribe from our marketing email list at any time by
                  clicking on the unsubscribe link in the emails that we send or by contacting us using the details
                  provided below. You will then be removed from the marketing email list &mdash; however, we may still
                  communicate with you, for example to send you service-related emails that are necessary for the
                  administration and use of your account, to respond to service requests, or for other non-marketing
                  purposes.{' '}
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c5 c1 c4">9. CONTROLS FOR DO-NOT-TRACK FEATURES</span>
              </p>
              <p className="c0">
                <span className="c3 c4">
                  Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                  (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not to have
                  data about your online browsing activities monitored and collected. At this stage no uniform
                  technology standard for recognizing and implementing DNT signals has been finalized. As such, we do
                  not currently respond to DNT browser signals or any other mechanism that automatically communicates
                  your choice not to be tracked online. If a standard for online tracking is adopted that we must follow
                  in the future, we will inform you about that practice in a revised version of this privacy notice.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c5 c1 c4">10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span>
              </p>
              <p className="c0">
                <span className="c1 c2">Briefly:</span>
                <span className="c3 c2">
                  &nbsp;Yes, if you are a resident of California, you are granted specific rights regarding access to
                  your personal information.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  California Civil Code Section 1798.83, also known as the &quot;Shine The Light&quot; law, permits our
                  users who are California residents to request and obtain from us, once a year and free of charge,
                  information about categories of personal information (if any) we disclosed to third parties for direct
                  marketing purposes and the names and addresses of all third parties with which we shared personal
                  information in the immediately preceding calendar year. If you are a California resident and would
                  like to make such a request, please submit your request in writing to us using the contact information
                  provided below.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  If you are under 18 years of age, reside in California, and have a registered account with us or one
                  of our Services, you have the right to request removal of unwanted data on our Services by notifying
                  us using our{' '}
                  <span className="contact" onClick={handleContact}>
                    contact form
                  </span>
                  . To request removal of such data, please contact us using the contact information provided below, and
                  include the email address associated with your account and a statement that you reside in California.
                  We will make sure the data is not publicly displayed on our Services, but please be aware that the
                  data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c5 c1 c4">11. DO WE MAKE UPDATES TO THIS NOTICE?</span>
              </p>
              <p className="c0">
                <span className="c1 c2">Briefly:</span>
                <span className="c3 c2">
                  &nbsp;Yes, we will update this notice as necessary to stay compliant with relevant laws.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c3 c4">
                  We may update this privacy notice from time to time. The updated version will be indicated by a
                  &ldquo;Last Updated&quot; date and the updated version will be effective as soon as it is accessible.
                  If we make material changes to this privacy notice, we may notify you either by prominently posting a
                  notice of such changes or by directly sending you a notification. We encourage you to review this
                  privacy notice frequently to be informed of how we are protecting your information.
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c5 c1 c4">12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
              </p>
              <p className="c0">
                <span className="c3 c4">
                  If you have questions or comments about this notice, you may email us using our{' '}
                  <span className="contact" onClick={handleContact}>
                    contact form
                  </span>
                  .
                </span>
              </p>
              <p className="c0 c9"></p>
              <p className="c0">
                <span className="c5 c1 c4">
                  13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                </span>
              </p>
              <p className="c0">
                <span className="c7">
                  Based on the applicable laws of your country, you may have the right to request access to the personal
                  information we collect from you, change that information, or delete it in some circumstances. To
                  request to review, update, or delete your personal information, please contact us using our{' '}
                  <span className="contact" onClick={handleContact}>
                    contact form
                  </span>
                  . We will respond to your request within 30 days.
                </span>
              </p>
            </React.Fragment>
          </Text>
        </Box>
      </Container>
    </PolicySectionWrapper>
  );
};

PolicySection.propTypes = {
  title: PropTypes.object,
  content: PropTypes.object,
  row: PropTypes.object,
};

PolicySection.defaultProps = {
  title: {
    fontSize: ['20px', '24px', '26px', '30px', '30px'],
    fontWeight: 'bold',
    color: '#fff',
    pt: ['40px', '60px', '60px', '60px', '60px'],
    pb: ['15px', '25px', '25px', '25px', '25px'],
    lineHeight: '1',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  content: {
    fontSize: ['14px', '16px', '18px', '18px', '18px'],
    fontWeight: 'normal',
    color: '#fff',
    pb: ['50px', '75px', '75px', '75px', '75px'],
    lineHeight: '1.5',
    fontStyle: 'normal',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
};

export default PolicySection;
